import {
  BusinessUnitTypes,
  useBusinessUnitContext,
} from '../BusinessUnitContext'
import { useSiteSettingsContext } from './SiteSettingsContext'
import { BusinessUnitLandingPageList } from './BusinessUnitLandingPageList'
import { find, propEq, isNil } from 'ramda'

export const useFindBusinessUnitLandingPageForCurrentLocale = () => {
  const currentBusinessUnit = useBusinessUnitContext()
  const siteSettings = useSiteSettingsContext()

  let businessUnitLandingPage = ''

  switch (currentBusinessUnit) {
    case BusinessUnitTypes.PASSENGER:
      businessUnitLandingPage = siteSettings.passengerFerriesSectionSlug
      break
    case BusinessUnitTypes.FREIGHT:
      businessUnitLandingPage = siteSettings.freightShippingSectionSlug
      break
    case BusinessUnitTypes.LOGISTICS:
      businessUnitLandingPage = siteSettings.logisticsSolutionsSectionSlug
      break
    case BusinessUnitTypes.GROUP:
      businessUnitLandingPage = siteSettings.groupSectionSlug
      break
    default:
      businessUnitLandingPage = ''
  }

  return businessUnitLandingPage
}

export const useFindBusinessUnitLandingPageForSpecificLocale = (locale) => {
  const currentBusinessUnit = useBusinessUnitContext()
  const siteSettingsForSpecificLocale = find(propEq('locale', locale))(
    BusinessUnitLandingPageList.items,
  )

  if (isNil(siteSettingsForSpecificLocale)) return ''

  let businessUnitLandingPage = ''

  switch (currentBusinessUnit) {
    case BusinessUnitTypes.PASSENGER:
      businessUnitLandingPage =
        siteSettingsForSpecificLocale.passengerFerriesSectionSlug
      break
    case BusinessUnitTypes.FREIGHT:
      businessUnitLandingPage =
        siteSettingsForSpecificLocale.freightShippingSectionSlug
      break
    case BusinessUnitTypes.LOGISTICS:
      businessUnitLandingPage =
        siteSettingsForSpecificLocale.logisticsSolutionsSectionSlug
      break
    case BusinessUnitTypes.GROUP:
      businessUnitLandingPage = siteSettingsForSpecificLocale.groupSectionSlug
      break
    default:
      businessUnitLandingPage = ''
  }

  return businessUnitLandingPage
}
