import {
  mapBusinessUnit,
  TopNavigation as Navigation,
  useSetLocale,
} from '@dfds-frontend/navigations'
import { useAppShellContext } from '../AppShellProvider'
import { isBookingFlowPage } from './helpers'
import { Login } from '../Login'

const TopNavigation = ({ locale, businessUnit, slugId }) => {
  const { navigationCollection } = useAppShellContext()
  useSetLocale(locale)
  const bu = mapBusinessUnit(businessUnit)
  const forceMobileNavigationView = isBookingFlowPage(slugId, bu)

  return (
    <Navigation
      businessUnit={bu}
      navigations={navigationCollection}
      forceMobileNavigationView={forceMobileNavigationView}
    >
      <Login />
    </Navigation>
  )
}

export default TopNavigation
