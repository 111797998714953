import {
  FooterNavigation as Navigation,
  getFooterLocaleSwitcherUrls,
  mapBusinessUnit,
} from '@dfds-frontend/navigations'
import { useEffect, useState } from 'react'
import { sharedContentClientRest } from '../../apollo/client'
import { useAppShellContext } from '../AppShellProvider'
import { isBookingFlowPage } from './helpers'
import { getAllLocalizedLandingPaths } from '@dfds-frontend/contentful-api-calls'

/**
 * TODO: remove updateRoutesWithFallback function when freight pages are completely
 * migrated to the Shared Content space.
 * For documentation check: https://dfds.visualstudio.com/CET/_workitems/edit/459274
 */
const updateRoutesWithFallback = (routes, fallbackToLanding) => {
  for (const key in fallbackToLanding) {
    if (!routes[key] || routes[key].length === 0) {
      routes[key] = fallbackToLanding[key]
    }
  }
  return routes
}

const FooterNavigation = ({
  locale,
  slug,
  businessUnit,
  slugId,
  localizedRoutes,
}) => {
  const bu = mapBusinessUnit(businessUnit)
  const { footerCollection } = useAppShellContext()

  let [availableRoutes, setAvailableRoutes] = useState({})
  let [FFLLandingPaths, setFFLLandingPaths] = useState({})

  useEffect(() => {
    if (!slug || !locale) return
    getFooterLocaleSwitcherUrls(sharedContentClientRest, locale, slug)
      .then((results) => setAvailableRoutes(results.urlsWithFallbacks))
      .catch((err) =>
        console.warn(
          `Locale switcher url query for locale: ${locale} and slug: ${slug}, didn't return any results`,
          err,
        ),
      )

    if (businessUnit === 'FREIGHT') {
      getAllLocalizedLandingPaths(
        sharedContentClientRest,
        'freight-ferries-and-logistics',
      )
        .then((results) => setFFLLandingPaths(...results))
        .catch((err) =>
          console.warn(
            `Error fetching Freight Ferries and Logistics landing pages: `,
            err,
          ),
        )
    }
  }, [locale, slug, businessUnit])

  const routes = {
    ...availableRoutes,
    ...localizedRoutes,
  }

  updateRoutesWithFallback(routes, FFLLandingPaths)

  const footerData = footerCollection.items.find(
    (navigations) => mapBusinessUnit(navigations.businessUnit) === bu,
  )

  return (
    <Navigation
      footerData={footerData}
      useSmallVariant={isBookingFlowPage(slugId, bu)}
      localizedRoutes={routes}
      locale={locale?.toLowerCase()}
      businessUnit={businessUnit}
      slugId={slugId}
    />
  )
}

export default FooterNavigation
